$li-padding: 20px;

.travellers-list-link {
  min-width: 50%;
}

.travellers-list-item {
  .ant-list-item-action {
    min-width: 100px;
    max-width: 50%;

    li:first-child {
      position: unset;
      padding-right: $li-padding;
    }
    li:last-child {
      position: unset;
      padding-left: $li-padding;
      padding-right: $li-padding;
    }
  }
}
