.travellers-form {
  .ant-picker {
    width: 100%;
  }
}

.embedded-form-container {
  padding: 10px;
  height: 100%;
}
