.billing-fulfillment-form-generator {
  .ant-input-number {
    width: 100%;
  }

  .billing-fulfillment-title-col {
    .ant-typography {
      margin-bottom: 0;
    }
  }

  .billing-fulfillment-form-inputs {
    width: 100%;
  }

  .billing-fulfillment-save-btn-col {
    display: flex;
    justify-content: right;
  }
}
