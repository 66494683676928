.section-inputs-points-of-interest {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: end;
}
.title {
    padding: 0;
    margin: 0;
    font-size: 20px;
    font-weight: 400;
}
.subtitle {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 300;
}
.card {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.input {
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.sector-travel-policies {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.card-list {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    border: 1px solid #ccc;
    padding: 10px; 
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); 
    align-items: center;
    border-radius:5px

}
.cursor-pointer {
    cursor: pointer;
}
.card-margin {
    margin: 16px 0px;
}

.title-margin {
    margin-bottom: 16px;
}
.input-with-addon {
    display: flex;
    align-items: center;
}

.inputNumero {
    border: none;
    background: none;
    font-size: 14px;
    color: inherit;
    text-align: left;
    padding: 0;
    width: 35px;
}

.inputNumero:focus {
    outline: none;
}

.addon {
    font-size: 14px;
    color: #666;
}
.margin-global-budget {
    margin-right: 4px;
}
.card-grouped{
    display: flex;
    flex-direction: column;
    gap:16px
}
