.grey-background {
  background: #f8f8f8;
}

.space-witdh-100 {
  width: 100%;
}

.divider-transparent {
  border-color: transparent;
}

.clickable-effect {
  cursor: pointer;
}

.center-content {
  display: flex;
  justify-content: center;
}

.no-text-underline {
  text-decoration: none !important;
}

.links-divider {
  margin-right: 5px;
  margin-left: 5px;
  color: var(--primary-color);
}

.list-item {
  border-bottom: 1px solid #f0f0f0 !important;
}

.disabled-link {
  pointer-events: none;
}
