// Center the logo with respect to the sidebar and header items.
.logo {
  height: 60px;
  display: flex;
  align-items: center;

  div img {
    padding-left: 12px;
  }
}
