$small-gap: 16px;
// Custom style to limit the scroll to the content of the page.
.layout-container {
  overflow-x: hidden;
  .content {
    margin-left: $small-gap;
    padding-right: $small-gap;
    padding-bottom: $small-gap;
    height: 60vh;
    overflow-y: scroll;
  }
}
